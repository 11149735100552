<template>
  <footer
    class="bg-white rounded-lg shadow sm:flex sm:items-center sm:justify-between p-4 sm:p-6 xl:p-8 dark:bg-gray-800 antialiased"
  >
    <p class="mb-4 text-sm text-center text-gray-500 dark:text-gray-400 sm:mb-0">
      &copy; 2024 <a
        href="https://sonnenglas.net/"
        class="hover:underline"
        target="_blank"
      >Sonnenglas.net</a>
      | <a
        href="mailto:it@sonnenglas.net"
        class="hover:underline"
      >Kontakt</a>
    </p>
  </footer>
</template>
